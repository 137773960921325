import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'


const Modal = () => externalComponent('modal')
const FormInput = () => externalComponent('formInput')
const CardIcon = () => externalComponent('cardIcon')
const ButtonSelect = () => externalComponent('buttonSelect')
const FormSelect = () => externalComponent('formSelect')

export default {
  name: 'ModalBookingConfirm',

  components: {
    Modal,
    FormInput,
    CardIcon,
    ButtonSelect,
    FormSelect,
  },

  props: ['id'],

  data() {
    return {
      mode: "",
      booking: {
        id: "",
        status: "",
        disabled: true,
        days: [],
        guest: {
          first_name: "",
          phone: "",
          country: "",
          last_name: "",
          email: "",
          state: "",
          message: ""
        }
      },
      
      actions: {
        name: "Aplicable Rate",
        options: [
          {
            name: "(test)Summer - $4,000 USD Full Day.(test)",
            value: "(test)Summer - $4,000 USD Full Day(test)."
          }
        ],
        selected: "",
        show: true,
        placeholder: "Select a tariff"
      }
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideModalBookingConfirm');
    },

    getBooking: async function(id) {
      let booking,
        days = "";

      await axios
        .get(apiDomain + '/booking/' + id)
        .then(res => booking = res.data)
        .catch(err => this.close());
      
      this.booking.id = booking._id;
      this.booking.status = booking.status;
      this.booking.guests = booking.guests;
      this.booking.guest = booking.guest[0];
      this.booking.disabled = true;
      this.booking.days = booking.days;

      for (let index = 0; index < booking.days.length; index++) {
        days += (days.length > 9 ? ' " ' : '') +
          (new Date(
            booking.days[index].substring(0, 4), 
            parseInt(booking.days[index].substring(4, 6))-1, 
            booking.days[index].substring(6, 8))
          ).toLocaleDateString('en-US').split('/').reverse().join('/'); //+ ' " ';
      }
      
      this.booking.days = days;
    },

    confirmRequest: async function() {
      if( this.actions.selected !== "" && this.booking.id ) {
        let response;
        // re confirmacion de reserva
        await axios
          .post(apiDomain + '/booking/confirmRequest/' + this.booking.id + '/' + this.actions.selected)
          .then(res => response = res)
          .catch(err => this.close());

        if (typeof response !== 'undefined' && response.status === 200) {
          this.$toast.success('Success booking confirm.');
          this.close();
        } else {
          this.$toast.error('Error booking confirm');
        }
      } else {
        this.$toast.error('Error with booking or tariff selected');
      }
    },

    getTariffs: async function() {
      let response,
        data = [],
        options = [];

      await axios
        .get(apiDomain + '/tariff')
        .then(res => response = res)
        .catch(err => this.close());

      if (typeof response !== 'undefined' && response.status === 200) {
        data = response.data;

        for (let index = 0, length = data.length; index < length; index++) {
          if( String(data[index][2].value).toLowerCase() == 'activa' )
            options.push({ name: data[index][0].value, value: data[index][0].id })
        }

      this.actions.options = options;
      } else {
        this.$toast.error('Error to get tariffs');
        this.close();
      }
    }
  },

  mounted() {
    this.getBooking(this.id);
    this.getTariffs();
  },

  watch: {
    'actions.selected': function(newValue, oldValue) {
      switch (newValue) {
        case 'confirmRequest':
          this.confirmRequest();
          break;
        case 'cancelRequest':
          this.cancelRequest();
          break;
      
        default:
          break;
      }
    }
  }
}
