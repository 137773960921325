import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'

const Modal = () => externalComponent('modal')
const FormInput = () => externalComponent('formInput')

export default {
  name: 'ModalCreateBooking',

  components: {
    Modal,
    FormInput,
    DatePicker
  },

  data() {
    return {
      property: {
        title: ''
      },

      booking: {
        days: null,
        guests: 0
      },

      guest: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        province: '',
        message: ''
      },

      calendar: {
        reserved: [],
        selected: "",
        msg: '',
        class: 'available',
        daysOfMonth: []
      },

      highlighted: [new Date(2021, 8, 24), new Date(2021, 8, 29)],
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideModalCreateBooking');
    },

    createBooking: async function() {
      let response;

      // try {
      //   parseInt(this.booking.guests);
      // } catch (error) {
      //   this.$toast.warning('Add valid number of guests');
      //   return
      // }

      if(
        this.booking.days !== null &&
        this.booking.days.length > 0 &&
        this.booking.guests !== null &&
        this.booking.guests > 0 &&
        this.first_name !== '' &&
        this.last_name !== '' &&
        this.email !== '' &&
        this.phone !== '' &&
        this.country !== '' &&
        this.province !== '' &&
        this.message !== ''
      ) {
        await axios
          .post(apiDomain + '/booking/', { booking: this.booking, guest: this.guest })
          .then(res => response = res)
          .catch(err => console.log(err));

        if (response.status === 200) {
          this.$toast.success('Se agregó la reserva ' + this.booking);

          this.close();
        } else {
          this.$toast.error('Error al agregar la reserva');
        }
      } else {
        this.$toast.error('Complete all data.');
      }
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    getDaysOfMonth: async function(e = null) {
      let month = null;
      if(e === null)
        month = new Date().getMonth();
      else
        month = e.getMonth();
      
      await axios
      .get(apiDomain + "/calendar/month/" + month)
      .then(res => {
        this.calendar.daysOfMonth = res.data;

      })
      .catch();
    },

    getClasses(date = null) {
      let res = '';
      date = date.toISOString().split('T')[0];
      this.calendar.daysOfMonth.find( v => {
        if( new Date(v.date).toISOString().split('T')[0] === date ) {
          if( v.status === 'pending' )
            res = "highlight highlight--pending";
          if( v.status === 'paid' )
            res = "highlight highlight--paid";
          if( v.status === 'sent' )
            res = "highlight highlight--sent";
          if( v.status === 'canceled' )
            res = "highlight highlight--canceled";
          if( v.status === 'expired' )
            res = "highlight highlight--expired";
          if( v.status === 'blocked' )
            res = "highlight highlight--blocked";
        }
      } );
      return res;
    },
  },

  mounted() {
    this.getDaysOfMonth();
  }
}
