import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

import ModalCreateBooking from '@/components/modals/modalCreateBooking'
import ModalBookingDetail from '@/components/modals/modalBookingDetail'
import ModalBookingConfirm from '@/components/modals/modalBookingConfirm'

const Card = () => externalComponent('card')
const CardIcon = () => externalComponent('cardIcon')
const CardButton = () => externalComponent('cardButton')
const Toolbar = () => externalComponent('toolbar')
const Table = () => externalComponent('table')
const Pagination = () => externalComponent('pagination')
const ModalDetail = () => externalComponent('modalDetail')
const FormButton = () => externalComponent('formButton')
const FormInput = () => externalComponent('formInput')

export default {
  name: 'Bookings',

  components: {
    Card,
    CardIcon,
    CardButton,
    Toolbar,
    Table,
    Pagination,
    ModalCreateBooking,
    ModalBookingDetail,
    ModalBookingConfirm,
    ModalDetail,
    FormButton,
    FormInput
  },

  data() {
    return {
      id: "",
      input: '',
      booking: [],
      selected: [],
      isModalBookingDetail: false,
      isModalCreateProperty: false,
      isModalCreateStay: false,
      isModalCreateBooking: false,
      isModalBookingConfirm: false,
      resume: {
        charges: 0,
        invoices: 0,
      },
      bookings: {
        mainAction: {
          name: 'Crear reserva',
          emit: 'showModalCreateBooking'
        },

        action_: {
          name: 'Acciones',
          options: [
            {
              name: 'Eliminar',
              value: 'deleteBooking'
            }
          ]
        },

        search: {
          name: 'Buscar',
          placeholder: ''
        },

        sections: [
          {
            name: 'Todas las reservas',
            value: 'all'
          },
          {
            name: 'Envíadas',
            value: 'sent'
          },
          {
            name: 'Pagadas',
            value: 'paid'
          },
          {
            name: 'Canceladas',
            value: 'canceled'
          }
        ],

        section: 'charges',

        columns: [
          {
            name: 'Nombre',
            // checkbox: true
          },
          {
            name: 'Correo'
          },
          {
            name: 'Fecha de entrada'
          },
          {
            name: 'Pasajeros'
          },
          {
            name: 'Estado'
          }
        ],
        rows: [],
        pages: 0,
        currentPage: 1
      },
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideModalBookingDetail');
    },

    doAction: function(action) {
      switch (action) {
        case 'deleteBooking':
          this.deleteBooking();
          break;
      }
    },

    searchBookings: async function(e) {
      if( e.length > 2 ) {
        let response,
          data;

        data = { search: e };

        await axios
          .post(apiDomain + '/booking/search', data)
          .then(res => response = res)
          .catch(err => error = true);
      
        this.bookings.rows = response.data;
        this.traslateStatus(this.bookings.rows);
      }

      if( e.length === 0 )
        this.getBookings();
    },

    orderMovements: function() {

    },

    traslateStatus(array) {
      array.forEach(element => {

        switch (element[4].value.toLowerCase() ) {
          case "pending":
            element[4].value = "Pendiente";
            break;
          case "expired":
            element[4].value = "Expirado";
            break;
          case "canceled":
            element[4].value = "Cancelado";
            break;
        
          default:
            break;
        }
      });
    },

    getBookings: async function() {
      let response;

      await axios
        .get(apiDomain + '/booking/')
        .then(res => response = res)
        .catch(err => error = true);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.bookings.rows = response.data;

        this.traslateStatus(this.bookings.rows);
      } else {
        this.$toast.error('Error en la consulta de las reservas');
      }
    },

    deleteBooking: async function() {
      if( this.selected.length > 0 ) {
        await axios
        .delete(apiDomain + '/booking/', { data: this.selected })
        .then(res => this.getBookings())
        .catch(err => error = true);
      }
    },

    tabSections: async function(opc = null) {
      if( opc !== null || opc.length < 1){
        let response;
        await axios
          .post(apiDomain + '/booking/filter', {filter: opc})
          .then(res => response = res)
          .catch(err => error = true);

        if (typeof response !== 'undefined' && response.status === 200) {
          this.bookings.rows = response.data;
          this.traslateStatus(this.bookings.rows);
        } else {
          this.$toast.error('Error en la consulta de las reservas');
        }
      }
    },

    showModalBookingDetail: async function(id) {
      this.id = id;
      this.isModalBookingDetail = true; 
      return
      await axios
        .get(apiDomain + '/booking/' + id)
        .then(res => this.booking = res.data)
        .catch(err => error = true);

      this.booking.header = {
        main: {
          title: 'No. de Reserva',
          value: 1231123
        },
        items: [
          {
            title: 'Nombre',
            value: (typeof this.booking.guest[0] !== 'undefined' && this.booking.guest[0].last_name !== 'undefined')
              ? this.booking.guest[0].first_name + ' ' + this.booking.guest[0].last_name
              : 'Sin información'
          },
          {
            title: 'Fecha de reserva',
            value: (typeof this.booking.createdAt !== 'undefined')
              ? new Date(this.booking.createdAt).toLocaleDateString("es-MX")
              : 'Sin información'
          },
          {
            title: 'Pasajeros',
            value: (typeof this.booking.guests !== 'undefined')
              ? this.booking.guests
              : 'Sin información'
          }
        ]
      };

      this.booking.extras = [
        {
          title: 'Contacto',
          description: 
          ( 
            (this.booking.guest[0].phone ? 'Telefono: ' + this.booking.guest[0].phone : '  Sin información ') + 
            (this.booking.guest[0].email ? '  ____  Email: ' + this.booking.guest[0].email : '')
          )
        },
        {
          title: 'Ubicacion',
          description: 
          ( 
            (this.booking.guest[0].country ? 'País: ' + this.booking.guest[0].country : '  Sin información ') + 
            (this.booking.guest[0].province ? '  ____  Estado: ' + this.booking.guest[0].province : '')
          )
        },
        {
          title: 'Mensaje',
          description: (this.booking.guest[0].message ? this.booking.guest[0].message : '  Sin información ')
        }
        // {
        //   title: 'Telefono',
        //   description: (this.booking.guest[0].phone ? 'Telefono: ' + this.booking.guest[0].phone : '  Sin información ')
        // },
        // {
        //   title: 'Email',
        //   description: (this.booking.guest[0].email ? '  -  Email: ' + this.booking.guest[0].email : '')
        // },
        // {
        //   title: 'País',
        //   description: (this.booking.guest[0].country ? 'País: ' + this.booking.guest[0].country : '  Sin información ')
        // },
        // {
        //   title: 'Estado',
        //   description: (this.booking.guest[0].province ? '  -  Estado: ' + this.booking.guest[0].province : '')
        // },
        // {
        //   title: 'Mensaje',
        //   description: (this.booking.guest[0].message ? this.booking.guest[0].message : '  Sin información ')
        // }
      ];
        
      
    },
    
    hideModalBookingDetail: function() {
      this.getBookings();
      this.isModalBookingDetail = false;
    },

    showModalCreateBooking: function() {
      this.isModalCreateBooking = true;
    },

    hideModalCreateBooking: function() {
      this.getBookings();
      this.isModalCreateBooking = false;
    },

    showModalBookingConfirm: function() {
      this.isModalBookingConfirm = true;
    },

    hideModalBookingConfirm: function() {
      this.getBookings();
      this.isModalBookingConfirm = false;
      this.isModalBookingDetail = true; 
    }
  },

  mounted() {
    this.getBookings();

    EventBus.$on('hideModalBookingDetail', () => {
      this.hideModalBookingDetail();
    });

    EventBus.$on('hideModalCreateBooking', () => {
      this.hideModalCreateBooking();
    });

    EventBus.$on('showModalBookingConfirm', () => {
      this.showModalBookingConfirm();
    });

    EventBus.$on('hideModalBookingConfirm', () => {
      this.hideModalBookingConfirm();
    });
  }
}
