import axios from 'axios'
import apiDomain from '@/config/api.js'
import { manager } from '@/config/domains.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

const Modal = () => externalComponent('modal')
const FormInput = () => externalComponent('formInput')
const FormButton = () => externalComponent('formButton')
const CardIcon = () => externalComponent('cardIcon')
const ButtonSelect = () => externalComponent('buttonSelect')

export default {
  name: 'ModalBookingDetail',

  components: {
    Modal,
    FormInput,
    CardIcon,
    ButtonSelect,
    FormButton
  },

  props: ['id'],

  data() {
    return {
      booking: {
        id: "",
        status: "",
        disabled: true,
        days: [],
        guest: {
          first_name: "",
          phone: "",
          country: "",
          last_name: "",
          email: "",
          state: "",
          message: ""
        },
        guests: "",
        link: {}
      },
      
      actions: {
        name: "Actions",
        options: [
          {
            name: "Confirm Request",
            value: "confirmRequest"
          },
          {
            name: "Cancel Request",
            value: "cancelRequest"
          },
        ],
        selected: "",
        show: true
      }
    }
  },

  methods: {
    any: function() {

    },

    close: function() {
      EventBus.$emit('hideModalBookingDetail');
    },

    getBooking: async function(id) {
      let booking,
        days = "";

      await axios
        .get(apiDomain + '/booking/' + id)
        .then(res => booking = res.data)
        .catch(err => this.close());

      if(!booking)
        return
      
      this.booking.id = booking._id;
      this.booking.invoice = booking.invoice;
      this.booking.status = booking.status;
      this.booking.guests = booking.guests;
      this.booking.guest = booking.guest[0];
      this.booking.disabled = true;
      
      if( booking.link[0] !== undefined && Object.values(booking.link[0]).length)
        this.booking.link = (manager + 'payment/' + booking.link[0]._id);

      for (let index = 0; index < booking.days.length; index++) {
        days += (days.length > 8 ? ' - ' : '') +
          (new Date(
            booking.days[index].substring(0, 4), 
            parseInt(booking.days[index].substring(4, 6))-1, 
            booking.days[index].substring(6, 8))
          ).toLocaleDateString('en-US').split('/').reverse().join('/'); //+ ' " ';
      }
      
      this.booking.days = days;
    },

    confirmRequest: async function() {
      // open modal confirmRequest
      EventBus.$emit('showModalBookingConfirm');
      this.close();
    },
    
    cancelRequest: async function() {
      // confirmacion de cancelar reserva
      if(confirm("Do you really want to delete?")) {
        await axios
        .post(apiDomain + '/booking/cancelRequest/' + this.booking.id)
        .then(res => response = res)
        .catch(err => this.close());
      }
    },

    copyClipboard: function() {
      document.execCommand('copy');
      this.$toast.info('Url Copiado');
    },

    scrollTopMax(e) {
      try {
        let $content = this.$el.firstChild.children[1];
        $content.scrollTop = $content.scrollTopMax;
      } catch (error) {}
    }
  },

  mounted() {
    this.getBooking(this.id);
  },

  watch: {
    'actions.selected': function(newValue, oldValue) {
      switch (newValue) {
        case 'confirmRequest':
          this.confirmRequest();
          break;
        case 'cancelRequest':
          this.cancelRequest();
          break;
      
        default:
          break;
      }
    }
  }
}
