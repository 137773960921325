const managerUrl = (process.env.NODE_ENV === 'production')
  ? "https://sneakattack.welovecorner.com/"
  : "http://localhost:8080/manager/";

const walletUrl = (process.env.NODE_ENV === 'production')
  ? "https://wallet.welovecorner.com/charge/card"
  : "https://wallet.welovecorner.com/charge/card";

const wlcServer  = (process.env.NODE_ENV === 'production')
  ? "https://api.welovecorner.com/"
  : "https://api.welovecorner.com/";

module.exports = {
  manager: managerUrl,
  wallet: walletUrl,
  wlcServer : wlcServer
}
